//TODO someday: fetch the popup instead of having the HTML in the dom
//SHARED FILE : USED ON DETAILS AND PRODUCT LIST
const FavoriteDropdown4i = function(){	
	let loading,
	contentTemplate = document.querySelector(".favDropdownTemplate").innerHTML,
	
	init = () => {
		document.getElementById("mainContent").addEventListener("click", events);
	},
	events = (e) => {
		if(e.target.classList.contains("faveLink")){
			e.preventDefault();
			toggle(e.target);
		}else if(e.target.classList.contains("cancel-delete-item")){
			e.preventDefault();
			removeMsg();
			document.querySelector('.ideaBoardList').querySelector("button").focus();
		}else if(e.target.classList.contains("confirm-delete-item")){
			e.preventDefault();
			deleteTheItem(e.target);
		}else if(e.target.classList.contains("boardName")){
			e.preventDefault();
			addToExistingBoard(e.target);
		}else if(e.target.classList.contains("closeFavorites")){
			e.preventDefault();
			closeRemove(true);
		}
	},
	removeMsg = () => {
		if(document.querySelector(".addToBoardMsg")) document.querySelector(".addToBoardMsg").remove();
	},
	deleteTheItem = async (el) => {
		let arr = el.getAttribute('href').split('/');
		let boardID = arr[0];
		let boardItemID = arr[1];
		loading = el.parentNode.parentNode.parentNode.parentNode.querySelector('.addItemLoading');
		
		loading.classList.remove("hide");		
		let responseJSON;
		const data = { boardID: boardID, boardItemID: boardItemID };
		try{
			const response = await fetch('/favs/deleteboarditem', {
				method: 'POST', 
				body: JSON.stringify(data),
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
			});
			//if(!response.ok) throw new Error("Error processing delete fav board item request");
			responseJSON = await response.json();
		}catch(error){
			console.log(error);
			// error function here
		}
		
		if(responseJSON) deletedFromBoard(responseJSON, el);
	},
	/*trapFocus = (e, el) => {
		e.preventDefault();
		e.stopPropagation();
		el.parentNode.parentNode.querySelector("buton").focus();
	},*/
	keyEvents = (e) => {
		if ((e.which || e.keyCode) === 27) closeRemove(true);
		else if (e.keyCode == 9 && e.target.classList.contains("viewAllFavs")) closeRemove(true);
	},
	toggle = (heartIcon) => {
		if(heartIcon.nextElementSibling && heartIcon.nextElementSibling.classList.contains("ideaBoardList")) closeRemove(true);
		else open(heartIcon);
	},
	open = (heartIcon) =>{
		closeRemove(false);
		heartIcon.insertAdjacentHTML('afterend', contentTemplate);
		heartIcon.setAttribute("aria-expanded", "true");
		
		let boardDropDown = heartIcon.nextElementSibling;
    		boardDropDown.removeEventListener('keydown', keyEvents);
    		boardDropDown.addEventListener('keydown', keyEvents);
    		if(boardDropDown.querySelector("button")) boardDropDown.querySelector("button").focus();
    		else if(boardDropDown.querySelector("a")) boardDropDown.querySelector("a").focus();
    		
    		let prodName = heartIcon.querySelector(".prodNameFavsBoard").innerHTML;
    		let existingBoards = boardDropDown.querySelectorAll(".addToBoardProdName");
    		for(let i = 0; i < existingBoards.length; i++){
    			existingBoards[i].innerHTML = prodName;
    		}
	},
	closeRemove = (setFocus) =>{
		let dropdown = document.querySelector(".ideaBoardList");
		// if it is not the template
		if(!dropdown.parentNode.classList.contains("favDropdownTemplate")){
			let heartIcon = dropdown.previousElementSibling;
			heartIcon.setAttribute("aria-expanded", "false");
			dropdown.remove();
			if(setFocus) heartIcon.focus();
		}
	},
	addToExistingBoard = async (boardName) =>{
		loading = boardName.parentNode.querySelector('.addItemLoading');
		let boardIDNumber = boardName.parentNode.querySelector('.board-id').value;
		let productID = (typeof (prodID) == 'undefined') ? boardName.closest('.faveLinkWrapper').parentNode.querySelector('.faveLinkProdID').value : prodID;
		if (productID == null) productID = document.getElementById('productid').value;		

		removeMsg();
		loading.classList.remove("hide");
		
		let responseJSON;
		const data = { boardID: boardIDNumber, productID: productID };
		try{
			const response = await fetch('/favs/additemtoboard', {
				method: 'POST', 
				body: JSON.stringify(data),
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
			});
			//if(!response.ok) throw new Error("Error processing save to favorite board request");
			responseJSON = await response.json();
		}catch(error){
			console.log(error);
		}
		
		if(responseJSON) addedToBoard(responseJSON, boardName);
	},
	turnFavOn = (heartIcon) => {
		heartIcon.classList.remove("textLtGray");
		heartIcon.classList.add("textRed");
	},
	turnFavOff = (heartIcon) => {
		heartIcon.classList.remove("textRed");
		heartIcon.classList.add("textLtGray");
	},
	addedToBoard = (response, el) => {
		loading.classList.add("hide");
    		if (response.success == "true") {
    			let heartIcon = el.closest('.faveLinkWrapper').querySelector('.faveLink');
    			turnFavOn(heartIcon);
    			el.insertAdjacentHTML('afterend', response.message); 
        		el.nextElementSibling.querySelector("a").focus();
    		}
	},
	deletedFromBoard = (response, el) => {
		loading.classList.add("hide");
            	if (response.isItemInOtherBoards == "False") {
            		let heartIcon = el.closest('.faveLinkWrapper').querySelector('.faveLink');
            		turnFavOff(heartIcon);
      		}
      		removeMsg();
      		document.querySelector('.ideaBoardList').querySelector("button").focus();
	};
	
	return {
		Init: init
	};
};

(function(){
	const favoriteDropDown = new FavoriteDropdown4i();
	favoriteDropDown.Init();
})();